import React, { useState, useEffect } from 'react';
import bookingHeader from "../Images/BrandingKit/mainBg.png";
import { LuX } from 'react-icons/lu';

const Booking = ({ isOpen, onClose }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const formData = new FormData(event.target);
            const response = await fetch(
                'https://docs.google.com/forms/d/e/1FAIpQLSf7sxLFKsmCIIdj3CqIojJiEs-6M5wMCDOKa3qu_SOdrvq1ew/formResponse',
                {
                    method: 'POST',
                    body: formData,
                    mode: 'no-cors'
                }
            );
            console.log(response);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsLoading(false);
        }
    };

    const redirectToHome = () => {
        setIsSubmitted(false);
        window.location.href = '/';
    };
    const handleClose = () => {
        setIsSubmitted(false);
        window.location.href = '/';
    };


    return (
        <div
            className="fixed inset-0 z-50 bg-[#0c0b0a] flex items-center justify-center p-4"
        >
            <div className="bg-white rounded-lg shadow-xl max-w-xl w-full max-h-[90vh] overflow-y-auto ">
                {!isSubmitted ? (
                    <>
                        <div
                            className="bg-cover bg-center w-full rounded-lg shadow-lg relative"
                            style={{
                                backgroundImage: `url(${bookingHeader})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'top',
                                backgroundColor: '#0c0b0a',
                            }}
                        >
                            <button onClick={handleClose} className="absolute top-4 right-4 text-2xl text-gray-200 hover:text-gray-700 z-50">
                                <LuX />
                            </button>
                            {isLoading}
                            <div className="bg-black bg-opacity-70 p-8 rounded-t-lg">
                                <h2 className="text-md text-white uppercase tracking-widest font-semibold">
                                    A Transformative Evening with Jeevajyoti
                                </h2>
                                <h1 className="text-2xl font-bold text-white mt-2">April 8,2025 | 6:30 pm - 8:30 pm</h1>
                                <h1 className="text-2xl font-bold text-white mt-2"> ClubWay Grand Hotel, Jorhat</h1>
                            </div>
                            <div className="bg-[#b08a6f] p-8 rounded-b-lg">
                                <form
                                    className="space-y-6"
                                    onSubmit={handleSubmit}
                                    target="_blank"
                                >
                                    <div>
                                        <label className="block text-sm font-bold text-black mb-2">
                                            Full Name
                                        </label>
                                        <input
                                            name="entry.1746166395"
                                            type="text"
                                            placeholder="Name"
                                            autoComplete
                                            required
                                            className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                                        />
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                        <div>
                                            <label className="block text-sm font-bold text-black mb-2">
                                                Email Address
                                            </label>
                                            <input
                                                name="entry.1817435118"
                                                type="email"
                                                placeholder="Email"
                                                required
                                                className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-sm font-bold text-black mb-2">
                                                Phone Number
                                            </label>
                                            <input
                                                name="entry.1736931708"
                                                type="text"
                                                placeholder="Phone Number"
                                                required
                                                className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-bold text-black mb-2">
                                            Profession
                                        </label>
                                        <input
                                            name="entry.1325078132"
                                            type="text"
                                            placeholder="Profession"
                                            required
                                            className="w-full p-3 rounded-lg border border-white bg-transparent text-white placeholder-white"
                                        />
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full py-3 mt-4 bg-[#6b5b4b] text-white font-bold rounded-lg hover:bg-[#5a4a3a]"
                                        >
                                            {isLoading ? 'Submitting...' : 'Submit'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="p-8 text-center">
                        <div className="mb-6">
                            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                                <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                </svg>
                            </div>
                            <h2 className="text-2xl font-bold text-gray-900 mb-2">Thank You!</h2>
                            <p className="text-gray-600">
                                Your session has been booked successfully. We'll contact you shortly to confirm your slot.
                            </p>
                        </div>
                        <button
                            onClick={redirectToHome}
                            className="bg-[#b58f49] text-white py-2 px-6 rounded-full hover:bg-[#8f6d35] transition-colors"
                        >
                            Return to Home
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Booking;