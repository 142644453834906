import React, { useEffect, useState } from 'react';
import img1 from '../Images/Instructor1.jpg';

const Instructor = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const instructorData = [
        {
            image: img1,
        }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === instructorData.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);
        return () => clearInterval(timer);
    }, [instructorData.length]);

    return (
        <div className="bg-[#191614] text-gray-200 w-full px-4 md:px-8 lg:px-12 relative overflow-hidden py-8">
            <div className="max-w-7xl mx-auto relative z-10">
                <div className="grid grid-cols-12 gap-4 items-center mb-16">
                    <div className="col-span-12 md:col-span-6 relative">
                        <div className="relative">
                            <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
                                OUR<span className="text-[#b58f49] relative"> INSTRUCTOR</span>
                            </h1>
                        </div>
                    </div>
                    <div className="hidden md:block sm:block col-span-12 md:col-span-6 items-center justify-center">
                        <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
                            <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    <div className="relative group">
                        <div className="relative overflow-hidden rounded-2xl shadow-xl">
                            <img
                                src={instructorData[currentIndex].image}
                                alt="Portrait"
                                className="w-full h-[300px]  object-cover object-center"
                            />
                        </div>
                        {/* <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                            {instructorData.map((_, index) => (
                                <button
                                    key={index}
                                    className={`w-2 h-2 rounded-full transition-colors duration-300 ${index === currentIndex ? 'bg-red-500' : 'bg-gray-400'
                                        }`}
                                    onClick={() => setCurrentIndex(index)}
                                />
                            ))}
                        </div> */}
                    </div>
                    <div className="space-y-8 md:px-0 px-3">
                        <div>
                            <h2 className="text-2xl md:text-4xl font-bold text-[#b58f49] mb-3">
                                Dr. Gautam Kr Choudhary
                            </h2>
                            <div className="h-0.5 w-24 bg-gradient-to-r from-[#b58f49] to-transparent" />
                        </div>
                        <p className="text-gray-300 text-md md:text-lg leading-relaxed">
                            Dr. Gautam Kr Choudhary, a disciple of Maharishi Mahesh Yogi, brings 30+ years of Transcendental Meditation expertise to Assam and Northeast India, transforming lives across diverse groups—children, militants, politicians, and more. His impactful guidance has inspired many to embrace the profound benefits of this meditative practice.
                        </p>
                        <div className="space-y-4">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Instructor;
