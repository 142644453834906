// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { OFFERINGS_DATA } from './Offerings/OfferingsData';

// const Offerings = () => {
//     const navigate = useNavigate();

//     const handleOfferingClick = (slug) => {
//         navigate(`/offerings/${slug}`);
//     };

//     const OfferingCard = ({ title, description, imageSrc, onClick }) => {
//         return (
//             <div
//                 className="w-full col-span-12 sm:col-span-6 lg:col-span-3 group cursor-pointer"
//                 onClick={onClick}
//             >
//                 <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-full min-h-[300px] max-h-[400px]">
//                     <div className="absolute inset-0">
//                         <img
//                             src={imageSrc}
//                             alt={`${title} - Offering`}
//                             className="w-full h-full opacity-30 object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
//                         />
//                         <div className="absolute inset-0 bg-gradient-to-b from-[#191614]/30 via-[#191614]/10 to-[#191614]/30" />
//                     </div>
//                     <div className="relative h-full flex flex-col p-4 sm:p-6">
//                         <div className="text-center mb-4">
//                             <div className="relative inline-block">
//                                 <h3 className="text-lg sm:text-xl font-bold text-white">
//                                     {title}
//                                 </h3>
//                                 <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent transition-all duration-500" />
//                             </div>
//                         </div>
//                         <div className="flex-grow flex flex-col justify-center">
//                             <p className="text-md md:text-sm text-gray-300 text-center 
//                                 mobile:opacity-100 
//                                 sm:opacity-0 
//                                 sm:group-hover:opacity-100 
//                                 transition-opacity 
//                                 duration-500 
//                                 delay-100 
//                                 leading-relaxed">
//                                 {description}
//                             </p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     };

//     return (
//         <div className="bg-[#191614] py-8 sm:py-12" id="offerings">
//             <div className="w-full px-4 sm:px-8 lg:px-16">
//                 <div className="relative">
//                     <div className="grid grid-cols-12 gap-4 items-center mb-8 sm:mb-12 lg:mb-16">
//                         <div className="col-span-12 md:col-span-6 relative">
//                             <div className="relative">
//                                 <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
//                                     CORE <span className="text-[#b58f49] relative">OFFERINGS</span>
//                                 </h1>
//                             </div>
//                         </div>
//                         <div className="hidden md:block col-span-6 items-center justify-center">
//                             <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
//                                 <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="grid grid-cols-12 gap-4 sm:gap-6 lg:gap-8">
//                     {OFFERINGS_DATA.map((offering) => (
//                         <OfferingCard
//                             key={offering.id}
//                             title={offering.title}
//                             description={offering.description}
//                             imageSrc={offering.imageSrc}
//                             onClick={() => handleOfferingClick(offering.slug)}
//                         />
//                     ))}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Offerings;




import React from 'react';
import { Link } from 'react-router-dom';
import offerings1 from '../Images/offerings1.jpg';
import offerings2 from '../Images/offerings2.jpg';
import offerings3 from '../Images/offerings3.jpg';
import offerings4 from '../Images/offerings4.jpg';

const Offerings = () => {
    return (
        <div className="bg-[#191614] py-8 sm:py-12" id="offerings">
            <div className="w-full px-4 sm:px-8 lg:px-16">
                <div className="relative">
                    <div className="grid grid-cols-12 gap-4 items-center mb-8 sm:mb-12 lg:mb-16">
                        <div className="col-span-12 md:col-span-6 relative">
                            <div className="relative">
                                <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
                                    CORE <span className="text-[#b58f49] relative">OFFERINGS</span>
                                </h1>
                            </div>
                        </div>
                        <div className="hidden md:block col-span-6 items-center justify-center">
                            <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
                                <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-4 sm:gap-6 lg:gap-8">

                    <div className="w-full col-span-12 sm:col-span-6 lg:col-span-3 group cursor-pointer">
                        
                        <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-full min-h-[300px] max-h-[400px]">

                            <div className="absolute inset-0">
                                <img
                                    src={offerings1}
                                    alt="Corporate-Offering"
                                    className="w-full h-full opacity-30 object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
                                />
                                <div className="absolute inset-0 bg-gradient-to-b from-[#191614]/30 via-[#191614]/10 to-[#191614]/30" />
                            </div>
                            <Link to="/corporate_wellness">
                                <div className="relative h-full flex flex-col p-4 sm:p-6">
                                    <div className="text-center mb-4">
                                        <div className="relative inline-block">
                                            <h3 className="text-lg sm:text-xl font-bold text-white">
                                                Corporate Wellness
                                            </h3>
                                            <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent transition-all duration-500" />
                                        </div>
                                    </div>
                                    <div className="flex-grow flex flex-col justify-center">
                                        <p className="text-md md:text-sm text-gray-300 text-center mobile:opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-500 delay-100 leading-relaxed">
                                            Join our Corporate Wellness programs to create a happier, healthier, and more productive workforce.
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="w-full col-span-12 sm:col-span-6 lg:col-span-3 group cursor-pointer">
                        <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-full min-h-[300px] max-h-[400px]">
                            <div className="absolute inset-0">
                                <img
                                    src={offerings2}
                                    alt="Educational Institutional Wellness"
                                    className="w-full h-full opacity-30 object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
                                />
                                <div className="absolute inset-0 bg-gradient-to-b from-[#191614]/30 via-[#191614]/10 to-[#191614]/30" />
                            </div>
                            <Link to="/educational_institutional_wellness">
                                <div className="relative h-full flex flex-col p-4 sm:p-6">
                                    <div className="text-center mb-4">
                                        <div className="relative inline-block">
                                            <h3 className="text-lg sm:text-xl font-bold text-white">
                                                Educational Institutional Wellness
                                            </h3>
                                            <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent transition-all duration-500" />
                                        </div>
                                    </div>
                                    <div className="flex-grow flex flex-col justify-center">
                                        <p className="text-md md:text-sm text-gray-300 text-center mobile:opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-500 delay-100 leading-relaxed">
                                            Empower your institution with tools that promote a balanced and thriving community.
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="w-full col-span-12 sm:col-span-6 lg:col-span-3 group cursor-pointer">
                        <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-full min-h-[300px] max-h-[400px]">
                            <div className="absolute inset-0">
                                <img
                                    src={offerings3}
                                    alt="Healthcare & Patient Care"
                                    className="w-full h-full opacity-30 object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
                                />
                                <div className="absolute inset-0 bg-gradient-to-b from-[#191614]/30 via-[#191614]/10 to-[#191614]/30" />
                            </div>
                            <Link to="/healthcare_&_patient_care">
                                <div className="relative h-full flex flex-col p-4 sm:p-6">
                                    <div className="text-center mb-4">
                                        <div className="relative inline-block">
                                            <h3 className="text-lg sm:text-xl font-bold text-white">
                                                Healthcare Professionals & Patient Care
                                            </h3>
                                            <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent transition-all duration-500" />
                                        </div>
                                    </div>
                                    <div className="flex-grow flex flex-col justify-center">
                                        <p className="text-md md:text-sm text-gray-300 text-center mobile:opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-500 delay-100 leading-relaxed">
                                            Experience how our programs uplift medical care, focusing on both patients and caregivers
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="w-full col-span-12 sm:col-span-6 lg:col-span-3 group cursor-pointer">
                        <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-full min-h-[300px] max-h-[400px]">
                            <div className="absolute inset-0">
                                <img
                                    src={offerings4}
                                    alt="Public Wellness"
                                    className="w-full h-full opacity-30 object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
                                />
                                <div className="absolute inset-0 bg-gradient-to-b from-[#191614]/30 via-[#191614]/10 to-[#191614]/30" />
                            </div>
                            <Link to="/public_wellness">
                                <div className="relative h-full flex flex-col p-4 sm:p-6">
                                    <div className="text-center mb-4">
                                        <div className="relative inline-block">
                                            <h3 className="text-lg sm:text-xl font-bold text-white">
                                                Public Wellness
                                            </h3>
                                            <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent transition-all duration-500" />
                                        </div>
                                    </div>
                                    <div className="flex-grow flex flex-col justify-center">
                                        <p className="text-md md:text-sm text-gray-300 text-center mobile:opacity-100 sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-500 delay-100 leading-relaxed">
                                            Transform your life with practices that create harmony and vitality
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Offerings;


