// import './App.css';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import Home from './components/Home';
// import EventsDetail from './components/Events/EventsDetail';
// import Association1 from './components/Associations/Maharishi';
// import Educational from './components/Offerings/Educational';
// import Healthcare from './components/Offerings/Healthcare';
// import Corporate from './components/Offerings/Corporate';
// import Public from './components/Offerings/Public';
// import Partner from './components/Partner';

// function App() {
//   return (
//     <>
//       <BrowserRouter>
//         <Routes>
//           <Route path='/' element={<Home />} />
//           <Route path='/association1' element={<Association1 />} />
//           <Route path="/corporate_wellness" element={<Corporate />} />
//           <Route path="/educational_institutional_wellness" element={<Educational />} />
//           <Route path="/healthcare_&_patient_care" element={<Healthcare />} />
//           <Route path="/public_wellness" element={<Public />} />
//           <Route path="/events/:slug" element={<EventsDetail />} />
//           <Route path="/partner" element={<Partner />} />
//         </Routes>
//       </BrowserRouter>
//     </>
//   );
// }

// export default App;



import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import Home from './components/Home';
import EventsDetail from './components/Events/EventsDetail';
import Association1 from './components/Associations/Maharishi';
import Educational from './components/Offerings/Educational';
import Healthcare from './components/Offerings/Healthcare';
import Corporate from './components/Offerings/Corporate';
import Public from './components/Offerings/Public';
import Partner from './components/Partner';
import Booking from './components/Booking';

ReactGA.initialize('G-VSV4H33RSG');

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    ReactGA.pageview(pagePath);
  }, [location]);
};

const App = () => {
  return (
    <BrowserRouter>
      <PageTracking />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/association1' element={<Association1 />} />
        <Route path="/corporate_wellness" element={<Corporate />} />
        <Route path="/educational_institutional_wellness" element={<Educational />} />
        <Route path="/healthcare_&_patient_care" element={<Healthcare />} />
        <Route path="/public_wellness" element={<Public />} />
        <Route path="/events/:slug" element={<EventsDetail />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/booking" element={<Booking />} />
      </Routes>
    </BrowserRouter>
  );
};

const PageTracking = () => {
  usePageTracking();
  return null;
};

export default App;
