import React from "react"

import { cn } from "../../lib/utils"

export const ShimmerButton = React.forwardRef(
    (
        {
            shimmerColor = "#b58f49",
            shimmerSize = "0.15em",
            shimmerDuration = "5s",
            borderRadius = "100px",
            background = "#191614",
            className,
            children,
            ...props
        },
        ref
    ) => {
        return (
            <button
                style={{
                    "--spread": "90deg",
                    "--shimmer-color": shimmerColor,
                    "--radius": borderRadius,
                    "--speed": shimmerDuration,
                    "--cut": shimmerSize,
                    "--bg": background
                }}
                className={cn(
                    "group relative z-0 flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap border border-white/10 px-4 md:px-6 py-3 text-[#b58f49] [background:var(--bg)] [border-radius:var(--radius)]",
                    "transform-gpu transition-transform duration-300 ease-in-out active:translate-y-px active:border-[#b58f49]",
                    className
                )}
                ref={ref}
                {...props}
            >
                {/* spark container */}
                <div
                    className={cn(
                        "-z-30 blur-[2px]",
                        "absolute inset-0 overflow-visible [container-type:size]"
                    )}
                >
                    {/* spark */}
                    <div className="absolute inset-0 h-[100cqh] animate-shimmer-slide [aspect-ratio:1] [border-radius:0] [mask:none]">
                        {/* spark before */}
                        <div className="absolute -inset-full w-auto rotate-0 animate-spin-around [background:conic-gradient(from_calc(270deg-(var(--spread)*0.5)),transparent_0,var(--shimmer-color)_var(--spread),transparent_var(--spread))] [translate:0_0]" />
                    </div>
                </div>
                {children}

                {/* Highlight */}
                <div
                    className={cn(
                        "inset-0 absolute size-full",
                        "rounded-full border border-[#b58f49]/50 text-sm font-medium shadow-[inset_0_-6px_10px_#b58f491f] ",
                        // transition
                        "transform-gpu transition-all duration-300 ease-in-out",
                        // on hover
                        "group-hover:shadow-[inset_0_-6px_10px_#b58f493f]",
                        // on click
                        // "group-active:shadow-[inset_0_-10px_10px_#b58f493f]"
                    )}
                />

                {/* backdrop */}
                <div
                    className={cn(
                        "absolute -z-20 [background:var(--bg)] [border-radius:var(--radius)] [inset:var(--cut)]"
                    )}
                />
            </button>
        )
    }
)

ShimmerButton.displayName = "ShimmerButton"
