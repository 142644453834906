import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LuMapPin } from 'react-icons/lu';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { EventsData } from './Events/EventsData';

const Events = () => {
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const EventsCard = ({ title, imageSrc, location, onClick }) => {
        return (
            <div className="relative w-full flex-shrink-0 group cursor-pointer px-4"
                onClick={onClick}>

                <div className="relative overflow-hidden rounded-2xl transition-all duration-300 h-[400px] bg-[#1E1B17]">
                    <div className="h-1/2 relative overflow-hidden">
                        <img
                            src={imageSrc}
                            alt={`${title} - Event`}
                            className="w-full h-full object-cover object-center transform transition-transform duration-500 group-hover:scale-110"
                        />
                        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#1E1B17]" />
                    </div>
                    <div className="relative p-6 h-1/2 flex flex-col">
                        <div className="mb-3">
                            <h3 className="text-xl text-center font-bold text-white group-hover:text-[#b58f49] transition-colors duration-300">
                                {title}
                            </h3>
                        </div>
                        <div className="space-y-2 mb-4">
                            <div className="flex items-center text-gray-400 text-sm mt-6">
                                <LuMapPin className="w-4 h-4 mr-2 text-[#b58f49]" />
                                <span>{location}</span>
                            </div>
                        </div>
                        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#b58f49] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                    </div>
                </div>
            </div >

        );
    };

    return (
        <div className="bg-[#191614] py-8" id="offerings">
            <div className="w-full px-8 md:px-16">
                <div className="relative">
                    <div className="grid grid-cols-12 gap-4 items-center mb-8 sm:mb-12 lg:mb-16">
                        <div className="col-span-12 md:col-span-6 relative">
                            <div className="relative">
                                <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-extrabold leading-tight text-center md:text-left">
                                    EARLIER <span className="text-[#b58f49] relative">EVENTS</span>
                                </h1>
                            </div>
                        </div>
                        <div className="hidden md:block col-span-6 items-center justify-center">
                            <div className="w-full h-0.5 bg-gradient-to-r from-[#C7A78A]/20 via-[#b58f49] to-[#C7A78A]/20 relative">
                                <div className="absolute -right-2 -top-1 w-2 h-2 rounded-full bg-[#b58f49]" />
                            </div>
                        </div>
                    </div>
                </div>
                <Slider {...settings}>
                    {EventsData.map((events, index) => (
                        <EventsCard
                            key={index}
                            title={events.title}
                            imageSrc={events.imageSrc}
                            date={events.date}
                            time={events.time}
                            location={events.location}
                            onClick={() => navigate(`/events/${events.slug}`)}
                        />
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Events;

