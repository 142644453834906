import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import About from '../components/About';
import DiscoverTM from '../components/DiscoverTM';
import OfferingsSection from '../components/Offerings';
import Events from '../components/Events';
import Banner from './Banner';
import Instructor from './Instructor';
import Testimonial from './Testimonial';
import Footer from '../components/Footer';
import ContactModal from './Contact/MainContact';

import Logo from '../Images/BrandingKit/Logo.png';
import writing from '../Images/BrandingKit/writing.png';
import mainBg from '../Images/BrandingKit/mainBg.png';
import tagLine from '../Images/BrandingKit/tagLine.png';

import { GoArrowRight } from "react-icons/go";
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiOutlinePlus } from 'react-icons/ai';

import { TextAnimate } from '../components/magicui/TextAnimate';
import '../style.css'
import { ScrollProgress } from './magicui/ScrollProgress';
import { ShimmerButton } from './magicui/ShimmerButton';

function Home() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hasModalBeenShown, setHasModalBeenShown] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // useEffect(() => {
    //     const modalShown = sessionStorage.getItem('hasModalBeenShown');
    //     if (modalShown === 'true') {
    //         setHasModalBeenShown(true);
    //     } else {
    //         const timer = setTimeout(() => {
    //             setIsModalOpen(true);
    //             setHasModalBeenShown(true);
    //             sessionStorage.setItem('hasModalBeenShown', 'true');
    //         }, 1000);
    //         return () => clearTimeout(timer);
    //     }
    // }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setHasModalBeenShown(true);
        sessionStorage.setItem('hasModalBeenShown', 'true');
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setIsMenuOpen(false);
    };

    return (
        <div className="bg-[#191614] text-white">
            <ScrollProgress />
            <div className="relative flex flex-col lg:flex-row">
                <div className="absolute inset-0">
                    <img
                        src={mainBg}
                        alt="BgImg"
                        className="h-full w-full object-cover opacity-[0.07] bg-no-repeat"
                    />
                </div>
                <div className="relative z-10 w-full lg:w-1/2 bg-[#191614]/80 lg:bg-[#191614]/0 p-6 sm:p-10 lg:p-16 flex flex-col justify-center h-screen">
                    <div className="max-w-xl mx-auto flex flex-col items-center lg:items-start text-center lg:text-left space-y-10 sm:space-y-8">
                        <img src={Logo} alt="Logo" className="h-48 sm:h-28 w-auto" />
                        <img src={writing} alt="Jeevajyoti" className="h-12 sm:h-20 w-auto" />
                        <img src={tagLine} alt="Jeevajyoti" className="h-12 sm:h-20 w-auto" />
                        <p className="text-base sm:text-lg text-white/90">
                            <TextAnimate animation="blurInUp" by="word" viewport={{ once: true }} >
                                Embrace your journey with JeevaJyoti — discover balance, serenity, and transformation through yoga and transcendental meditation.
                            </TextAnimate>
                        </p>
                        {/* <ShimmerButton
                            className=" flex items-center justify-center text-sm md:text-base font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg  focus:ring-1 focus:ring-[#b58f49]"
                            onClick={handleOpenModal}
                        >
                            <span className="tracking-wider mr-2">SIGN UP FOR AN UPCOMING EVENT</span>
                            <GoArrowRight className="h-5 w-5" />
                        </ShimmerButton> */}
                        <Link to="/booking">
                            <ShimmerButton
                                className=" flex items-center justify-center text-sm md:text-base font-medium transition-all duration-300 ease-in-out hover:translate-x-1 hover:shadow-lg  focus:ring-1 focus:ring-[#b58f49]"
                            >
                                <span className="tracking-wider mr-2">BOOK YOUR SLOT</span>
                                <GoArrowRight className="h-5 w-5" />
                            </ShimmerButton>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="absolute top-4 right-4 z-50">
                <button
                    onClick={toggleMenu}
                    className={`text-[#b58f49] text-3xl p-4 rounded-full transition-transform duration-800 ease-in-out ${isMenuOpen ? 'rotate-45' : ''}`}
                >
                    {isMenuOpen ? (
                        <span><AiOutlinePlus /></span>
                    ) : (
                        <span><RxHamburgerMenu /></span>
                    )}
                </button>
            </div>
            {isMenuOpen && (
                <div
                    className={`fixed top-0 left-0 h-screen flex justify-center items-center w-full bg-black bg-opacity-30 backdrop-filter backdrop-blur-lg z-40 transform transition-transform duration-800 ease-in-out`}
                >
                    <div className="p-8">
                        <ul className="space-y-8">
                            <li>
                                <button
                                    onClick={() => scrollToSection('offerings')}
                                    className="text-white text-2xl  center-text-button"
                                >
                                    Offerings
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={() => scrollToSection('events')}
                                    className="text-white text-2xl center-text-button"
                                >
                                    Events
                                </button>
                            </li>
                            <li>
                                <Link to="/partner">
                                    <button className="text-[#b58f49] text-2xl center-text-button">
                                        Partner With Us
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

            )}

            <div><About /></div>
            <div><DiscoverTM /></div>
            <div id="offerings"><OfferingsSection /></div>
            <Banner handleOpenModal={handleOpenModal} />
            <div id="events"><Events /></div>
            <div><Instructor /></div>
            <div><Testimonial /></div>
            <Footer handleOpenModal={handleOpenModal} />
            <ContactModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                className={`${isModalOpen ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-opacity duration-800 ease-in-out`}
            />
        </div>
    );
}

export default Home;
